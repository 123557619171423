@import "../../../assets/styles/helpers.scss";

.element {
  display: flex !important;
  align-items: center;
  gap: 16px;
  margin: 0 !important;
  span {
    border-radius: 50%;
    border: 0.5px solid var(--seperator-border-color);
    width: 46px;
    height: 42px;
    cursor: pointer;
    @include center;
    @include phone {
      display: none;
    }
  }
  .multiColorSvg {
    position: relative;
    transition: 0.2s;
    &:hover {
      border-color: $goldHover;
    }
    svg {
      path {
        stroke: var(--main-color);
      }
      rect {
        stroke: var(--main-color);
      }
    }
  }
}

.wallet {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--main-background);
  padding: 8px;
  border-radius: 100px;
  @media screen and (max-width: 630px) {
    display: none;
  }
  svg:nth-child(1) {
    path:nth-child(1) {
      fill: var(--main-color);
    }
    path:nth-child(3) {
      stroke: var(--main-color);
    }
  }
  svg:nth-child(2) {
    width: 24px;
    height: 24px;
    path {
      width: 100%;
      height: 100%;
      fill: var(--main-color-header);
    }
  }
}
.mobileMenu {
  width: 40px;
  height: 36px;
  border: 1px solid $blue;
  background-color: $blue;
  color: $white;
  border-radius: 50%;
  display: none;
  rect {
    fill: $white;
  }
  @include tablet {
    @include center;
  }
  @media (max-width: 1200px) {
    @include center;
  }
  @media (min-width: 1200px) {
    display: none;
  }
}
.balance {
  color: var(--main-color);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
}
.mobileMenuClose {
  background-color: var(--menu-icon-bg);
  border: none;
  path {
    stroke: $white;
  }
}

.options {
  display: none;
}

.optionsActive {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40px;
  left: -20px;
  padding: 4px;
  border: 1px solid var(--notification-border-color);
  background-color: var(--main-background-header);
  width: 100px;
  z-index: 9999;
  span {
    padding: 7px 15px;
    color: $black;
    @include Urbanist;
    font-weight: 500;
    border-radius: unset;
    border: unset;
    text-align: left;
    display: block;
    width: auto;
    &:hover {
      background: var(--notification-border-color);
      border-radius: 2px;
    }
  }
}

.menuListItem {
  align-items: center;
  position: relative;
  display: none;
  @media screen and (max-width: 1200px) {
    display: flex;
  }
  &:hover {
    .subMenuWrapper {
      display: flex;
    }
  }
  &:last-child {
    a {
      border: none;
    }
  }

  a {
    //Size, Weight, Line Height, Transform
    @include _text(16px, 600, 24px, uppercase);
    padding-right: 32px;
    border-right: 1px solid $borderPrimary;
    letter-spacing: 0.02em;
    transition: 0.2s;
    @media (max-width: 1200px) {
      font-size: 18px;
    }
    line-height: 38px;
  }
  &:hover {
    & > a {
      color: $text;
    }
  }
}

.language {
  //Size, Weight, Line Height
  @include _text(16px, 600, 24px);
  color: var(--main-color) !important;
  letter-spacing: 0.02em;
  transition: 0.2s;
  @media (max-width: 1200px) {
    font-size: 18px;
  }
  display: flex;
  align-items: center;
  &:hover {
    color: $text;
  }
  cursor: pointer;
  p {
    margin-left: 11px;
    margin-right: 3px;
    color: var(--main-color);
  }
  position: relative;
}

.languageActive {
  color: $gold !important;
}
.count {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  right: 5px;
  top: 5px;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  background: color("light-primary-gold");
  color: $white;
}
