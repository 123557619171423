@import "../../assets/styles/helpers.scss";

.element {
  background-color: var(--seperator-bg);
  padding-top: 80px;
  padding-bottom: 24px;
}

.footerContainer {
  @include laptop {
    flex-direction: column;
    gap: 42px;
  }
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
}

.leftSide {
  width: 28%;
  @include laptop {
    width: 100%;
  }
}

.footLogo {
  @include phone {
    display: flex;
    justify-content: center;
  }
  img {
    width: 139px;
    height: 60px;
    object-fit: contain;
  }
}

.qrcodeWrapper {
  padding: rem(12) 0 rem(12) rem(25);
  background-image: url("../../assets/images/graypattern.png");
  background-repeat: no-repeat;
  background-color: white;
  background-size: cover;
  width: 408px;
  @include tablet {
    width: 100%;
  }
  h4 {
    font-weight: 600;
    color: $black;
    line-height: 32px;
    font-size: 18px;
    margin-bottom: 20px;

    span {
      color: $blue;
    }
  }

  div {
    display: flex;
    align-items: center;

    span {
      margin: 0 21px;
    }
  }
}

.description {
  @include Urbanist;
  margin-top: 32px;
  color: $text;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 20px;
  width: 90%;
  @include laptop {
    margin-bottom: 32px;
  }
  @include phone {
    margin-top: 16px;
    text-align: center;
    width: 100%;
  }
}

.rightSide {
  width: 48%;
  @include laptop {
    width: 100%;
  }
}

.qrBox {
  @media (max-width: 400px) {
    flex-direction: column;
    gap: 10px;
  }
  span {
    @include Urbanist;
  }
}
