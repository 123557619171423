@import "../../assets/styles/helpers.scss";

.container {
  min-width: 50vw;
  min-height: 200px;
  background: $lightGray;
  color: $black;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @include tablet {
    flex-direction: column;
    width: 90vw;
  }
  @include laptop {
    width: 90vw;
  }
  @media (min-width: 1000px) and (max-width: 1500px) {
    width: 80vw;
  }
  .cookieImgWrapper {
    width: 180px;
    transform: translateY(-30px);
    @include tablet {
      width: 80px;
      transform: translateY(0px);
    }
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .cookieText {
    font-size: 20px;
    font-weight: bold;
    p {
      margin-top: 10px;
      font-size: 16px;
      font-weight: normal;
      width: 95%;
    }
  }
  .actionsWrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    a {
      font-size: 16px;
      text-decoration: underline;
      color: $black;
    }
  }
}
