@import "../../assets/styles/helpers.scss";

.button {
  @include standartWhiteText;
  color: var(--white);
  background-color: $blue;
  border-radius: 2px;
  width: 266px;
  height: 60px;
  @include center;
  text-transform: uppercase;
  border: rem(1.5) solid var(--main-color-blue);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
  &--transparent {
    background-color: transparent;
    color: var(--main-color-blue) !important;
    border-color: var(--main-color-blue) !important;
    &:hover {
      color: var(--main-color-blue) !important;
    }
  }
  &--flexible {
    font-size: vw(16);
  }
  &--height {
    &--small {
      height: 48px;
    }
  }
  &--width {
    &--small {
      width: 166px;
    }
    &--medium {
      width: 213px;
    }
  }
}
