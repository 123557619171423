@import "../../assets/styles/helpers.scss";

.element {
  background-color: var(--seperator-bg);

  border-top: 1px solid var(--seperator-bg);
  padding-top: 24px;
  padding-bottom: 24px;

  ul {
    display: flex;
    align-items: center;
    gap: 40px;
    @include laptop {
      order: 2;
    }
    @include tablet {
      flex-direction: column;
    }

    li {
      a {
        color: $text;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }
      &:hover {
        a {
          color: $gold;
        }
      }
    }
  }
}

.subfooterContainer {
  @include center(space-between, center);
  @include laptop {
    flex-direction: column;
    gap: 24px;
  }
  max-width: 1440px;
  margin: 0 auto;
}

.socialIcons {
  display: flex;
  gap: 8px;
  align-items: center;
  @include laptop {
    order: 1;
  }
}

.socialIcon {
  @include center;
  &:hover {
    color: $gold;
    border-color: $goldHover;
  }
  width: 58px;
  height: 48px;
  border-radius: 100px;
  border: 1px solid var(--seperator-border-color);
  @include center(center, center);
  color: $black;
  background-color: var(--main-background-header);

  a {
    display: flex;
    svg {
      path {
        fill: var(--main-color);
      }
    }
  }
}
