@import "../variables/breakpoints.scss";

@mixin phone {
  @media (max-width: $phone) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: $laptop) {
    @content;
  }
}

@mixin showMobile() {
  @media (max-width: $mobileShow) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin maxWidth {
  @media (min-width: $maxWidth) {
    @content;
  }
}


@mixin inRange($min, $max){
  @media screen and (min-width:$min) and (max-width:$max) {
    @content;
  }
}

@mixin maxRange($val){
  @media screen and (max-width: $val ) {
    @content;
  }
}

@mixin minRange($val){
  @media screen and (min-width: $val ) {
    @content;
  }
}

@mixin btw {
  @media (min-width: $tablet) and (max-width: $laptop) {
    @content;
  }
}
