.button {
  margin: 0 15px;
  width: 42px;
  height: 40px;
  border-radius: 50%;
  background: var(--main-background);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
}
