@import "../../../assets/styles/helpers.scss";

.element {
  border-radius: 10rem;
  cursor: pointer;
  max-height: 4rem;
  position: relative;
  &.hasType {
    &-1 {
      background-color: var(--main-background-header);
      cursor: pointer;
      margin-right: 0.8rem;
      padding: 2rem 1rem;
      border: 0.1rem solid var(--notification-border-color);
      border-radius: 50% !important;

      svg {
        path {
          stroke: var(--main-color);
        }
        rect {
          stroke: var(--main-color);
        }
      }

      &:hover {
        border: 0.1rem solid color("light-primary-gold-hover");
        transition: all 0.3s ease;
      }
    }
    &-2 {
      padding: 0.8rem 0.9rem;
      background: var(--main-background);
      margin-left: 1rem;

      span {
        color: var(--main-color);
      }

      span {
        &:first-child {
          svg {
            path {
              //stroke: var(--main-color);
            }
            rect {
              //stroke: var(--main-color);
            }
          }
        }
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }
}
