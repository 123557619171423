@import "../../assets/styles/helpers.scss";

.headerContainer {
  border-bottom: 1px solid var(--seperator-border-color);
  background: var(--main-background-header);
}

.wrapper {
  min-height: 7rem;
  @include center(space-between, center);
  max-width: 1440px;
  margin: 0 auto;
  nav {
    margin-left: 41px;
    margin-right: 10px;
    @include laptop {
      margin-left: 30px;
    }
    @include tablet {
      display: none;
    }
    @media screen and (max-width: 1300px) {
      margin-left: 32px;
    }
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
}

.leftSide {
  @include center(space-between, center);
}

.active {
  color: $gold !important;
}
.subActive {
  color: $gold !important;
  border-bottom: 1px solid $gold !important;
}

.logo {
  display: block;
  width: 87px;
  height: 38px;
  img {
    object-fit: contain;
    width: 100%;
  }
}

.menuList {
  display: flex;

  li {
    &:last-child {
      @include center(center, center);
      gap: 11px;
      @media (max-width: 990px) {
        display: none;
      }

      select {
        //Size, Weight, Line Height
        @include _text(16px, 600, 24px);
        letter-spacing: 0.02em;
        cursor: pointer;
        @media (max-width: 1200px) {
          font-size: vw(18);
        }
      }
    }
  }
}

.subNavList {
  ul {
    max-width: 1440px;
    margin: 0 auto;
    padding-left: 153px;
  }
  @media screen and (max-width: 1200px) {
    display: none;
  }

  a {
    border-right: none !important;
    padding-right: 0 !important;
    margin-right: 16px;
  }
  border-bottom: 0.5px solid var(--seperator-border-color);
}

.menuListItem {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  &:hover {
    .subMenuWrapper {
      display: flex;
    }
  }
  &:nth-child(7) {
    a {
      border: none;
    }
  }
  p:last-child {
    border-right: 1px solid var(--seperator-border-color);
  }
  a {
    //Size, Weight, Line Height, Transform
    @include _text(1.6rem, 600, 24px, uppercase);
    padding: 0 20px;
    border-right: 1px solid var(--seperator-border-color);
    color: var(--main-color);
    letter-spacing: 0.02em;
    transition: 0.2s;
    @media (max-width: 1200px) {
      font-size: vw(18);
    }
    &:hover {
      color: $text;
    }
    &:nth-child(3) {
      @media (max-width: 867px) {
        border-right: none;
      }
    }

    line-height: 2.4rem;
  }
  &:hover {
    & > a {
      color: $text;
    }
  }
  &:last-child {
    padding: 0 20px;
  }
}
.menuItemBorder {
  border-right: 1px solid var(--seperator-border-color);
}

.disabled {
  //Size, Weight, Line Height, Transform
  @include _text(1.6rem, 600, 24px, uppercase);
  padding: 0 20px;
  border-right: 1px solid var(--seperator-border-color);
  color: $text !important;
  letter-spacing: 0.02em;
  transition: 0.2s;
  @media (max-width: 1200px) {
    font-size: vw(18);
  }

  line-height: 2.4rem;
  cursor: pointer;
}

.subMenuList {
  display: flex;
  li {
    white-space: nowrap;
    &:not(&:first-child) {
      padding: 0 16px;
    }
    a {
      @include text("heading-14");
    }
    &:hover {
      & > a,
      select,
      svg {
        color: $text;
      }
    }
  }
}

.language {
  //Size, Weight, Line Height
  @include _text(16px, 600, 24px);
  letter-spacing: 0.02em;
  transition: 0.2s;
  color: var(--main-color);
  @media (max-width: 1200px) {
    font-size: vw(18);
  }
  display: flex;
  align-items: center;
  &:hover {
    color: $text;
  }
  cursor: pointer;
  p {
    margin-left: 11px;
    margin-right: 3px;
  }
  position: relative;
}

.options {
  display: none;
}

.optionsActive {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30px;
  padding: 4px;
  border: 1px solid var(--seperator-border-color);
  background-color: var(--main-background-header);
  width: auto;
  min-width: 146px;
  z-index: 9999;
  span {
    padding: 7px 15px;
    color: $black;
    @include Urbanist;
    font-weight: 500;
    &:hover {
      background: var(--seperator-border-color);
      border-radius: 2px;
    }
  }
}

.whitepaper {
  margin-top: 20px;

  a {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: var(--main-color);
  }
}

.languageActive {
  color: $gold !important;
}

.mobileMenu {
  position: absolute;
  left: -100%;
  display: none;
  background-color: var(--main-background-header);
  width: 100%;
  z-index: 1000;
  @include laptop {
    display: block;
  }
  min-height: 100vh;
  padding: 80px;
  overflow-y: scroll !important;
  @include tablet {
    padding: 25px;
  }
  @media (max-width: 1200px) {
    display: block;
  }
  transition: all 0.3s;
}

.mobileDeactive {
  left: 0 !important;
}

.mobileMenuList {
  display: flex;
  flex-wrap: wrap;
  & > div {
    width: 30%;
    margin-top: 25px;
    @include tablet {
      width: 50%;
    }
  }
  ul {
    h4 {
      //Size, Weight, Line Height, Transform, Color
      @include _text(24px, 500, 28px, capitalize, $black);
      color: var(--main-color);
      margin-bottom: 16px;
      text-transform: capitalize;
    }
    li {
      margin-top: 8px;
      a {
        //Size, Weight, Line Height
        @include _text(16px, 500, 24px);
        color: var(--main-color);
        @include Urbanist;
      }
    }
  }
}

.disabledMobileMenu {
  color: $text !important;
}

.mobileMenuListMainItem {
  //Size, Weight, Line Height, Transform
  @include _text(24px, 500, 28px, capitalize, $black);
  a {
    color: var(--main-color);
  }
  margin-bottom: 16px;
}

.createUserButton {
  color: var(--button-text);
  &:hover {
    color: var(--button-hover-text);
    border-color: var(--button-border);
  }
  min-width: max-content;
  padding: 0 10px;

  @media (max-width: 680px) {
    display: none !important;
  }
}

.connectWalletButton {
  min-width: max-content;
  padding: 0 10px;
  margin-top: 50px;
}

.close {
  background-color: transparent;
  border: none;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 46px;
  right: 46px;
  cursor: pointer;
}

.createButtonWrapper {
  @media screen and (max-width: $desktop) {
    display: none;
  }
}

.modalText {
  //Size, Weight, Line Height
  @include _text(32px, 400, 38px);
  color: $black;
  padding-top: 32px;
  padding-bottom: 40px;
  width: 85%;
}

.connectCards {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.buttonsWrapper {
  display: flex;
  gap: 20px;
  align-items: center;
  button {
    @include phone {
      display: none;
    }
    @include tablet {
      display: none;
    }
  }
}

.hide {
  display: none;
}
.subMenu {
  a {
    padding: 8px 0;
    font-size: 1.4rem;
    border-bottom: 1px solid transparent;
  }
}
