@import "../../assets/styles/helpers.scss";

.modalText {
  //Size, Weight, Line Height
  @include _text(32px, 400, 38px);
  padding-top: 32px;
  padding-bottom: 40px;
  width: 85%;
  color: var(--main-color);
}
.modalBg {
  background-color: var(--main-background-header);
  left: 50%;
  top: 50%;
  bottom: 0;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  width: 93%;
  max-width: 522px;
  padding: 40;
  z-index: 98;
}

.tabsWrapper {
  display: flex;
  justify-content: center;
}

.modalTitle {
  text-align: center;
  color: var(--main-color);
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 32px;
  word-break: break-all;
}

.connectCards {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--main-background-header);
  & * {
    color: var(--main-color);
  }
}

.close {
  background-color: transparent;
  border: none;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50px;
  right: 60px;
  cursor: pointer;
}

.terms {
  margin-bottom: 15px;
  margin-top: 30px;
  padding-top: 15px;
  color: $text;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
  a {
    color: var(--main-color);
  }
}
.wallet {
  p {
    &:first-child {
      font-size: 17px;
      margin-bottom: 10px;
    }
    &:last-child {
      margin-bottom: 20px;
      font-size: 12px !important;
    }
  }
}
