@import "../../../assets/styles/helpers.scss";

.wrapper {
  font-style: normal;
  font-weight: 500;

  a {
    color: color("light-primary-blue");
  }

  &.hasColor {
    &-black {
      color: var(--main-color);
    }
    &-white {
      color: white;
    }
    &-gold {
      color: color("light-primary-gold");
    }
    &-red {
      color: color("light-primary-red");
    }
    &-green {
      color: color("light-primary-green");
    }
    &-grey {
      color: color("light-primary-grey");
    }
  }

  &.hasSize {
    &-1 {
      font-size: 1.4rem;
      line-height: 2rem;
    }
    &-2 {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
    &-3 {
      font-size: 3.2rem;
      line-height: 3.8rem;
    }
    &-4 {
      font-size: 1.2rem;
      line-height: 2rem;
    }
    &-5 {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
  }

  &.hasFontFamily {
    &-urbanist {
      font-family: $urbanist;
    }
    &-manrope {
      font-family: $manrope;
    }
    &-nunito-sans {
      font-family: $nunito-sans;
    }
  }
}
