@import "../../../assets/styles/helpers.scss";

.footerList {
  display: flex;
  flex-wrap: wrap;
  @include tablet {
    flex-direction: column;
    align-items: center;
  }
  div {
    margin-bottom: 40px;
    margin-right: 10px;
    @media (min-width: 768px) {
      width: 30%;
    }
    @include tablet {
      margin-right: 0;
      text-align: center;
    }
    @media (min-width: 768px) {
      // &:nth-child(4) {
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: flex-end;
      // }
    }
    h4 {
      font-size: 24px;
      line-height: 28px;
      color: var(--main-color);
      font-weight: 500;
      text-transform: capitalize;
      @include tablet {
        font-size: 18px;
      }
      transition: 0.3s;
      &:hover {
        color: $text;
      }
    }
    ul {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      li {
        a {
          font-size: 1.4rem;
          line-height: 24px;
          color: var(--main-color);
          font-weight: 500;
          @include Urbanist;
          @include tablet {
            font-size: 14px;
          }
          transition: 0.3s;
          &:hover {
            color: $text;
          }
        }
      }
    }
  }

  span {
    font-size: 1.4rem;
    color: $text;
    h4 {
      color: $text;
    }
    cursor: pointer;
  }
}

.policyterm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
