@import "../../assets/styles/helpers.scss";

.wrapper {
  width: 100%;
  height: 76px;
  border: 0.1rem solid var(--seperator-border-color);
  border-radius: 2px;
  background-position: 100% 0;
  min-height: 80px;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/firstlayer.png");
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 20px;
  &.hasDarkMode {
    background-image: url("../../assets/images/walletbox-pattern-dark.png");
  }
  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-color);
    text-transform: uppercase;
    padding-left: 15px;
  }
}

.image {
  width: 68px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    width: 100%;
    height: 100%;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.active {
  padding-left: 3.3rem;
  border-left: 1px solid var(--light-primary-gray);
  margin-left: auto;
  margin-right: 10px;
}

.connected {
  color: var(--light-primary-grey);
  font-size: 14px;
  line-height: 2rem;
}
.reconnectButton {
  padding: 0.4rem 0 0 0 !important;
  background: transparent;
  color: $gold !important;
  font-weight: 600;
  border: none;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  text-align: end;
  text-transform: uppercase;
}
