* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  min-width: 8px;
  max-height: 8px;
  margin: 2px;
  background-color: var(--scrollbar);
  background-clip: padding-box;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  display: none;
}

:root {
  --white: #fff;
  --scrollbar: #e9e7e4;
  --main-background-header: #ffffff;
  --main-background: #f4f2ed;
  --main-color-header: #242121;
  --main-color: #242121;
  --main-color-blue: #285491;
  --menu-icon-bg: #285491;
  --input-border-color: #f8f7f6;
  --input-notification-border-color: #f8f7f6;
  --light-primary-gray: #e9e7e4;
  --light-primary-grey: #8c8987;
  --notification-toast-bg: #dbdbdb;
  --shadow: #a09f9e79;
  --line-color: #f8f7f6;
  --notification-border-color: #f8f7f6;
  --seperator-border-color: #e9e7e4;
  --button-text: #fff;
  --button-transparent-text: #000;
  --button-border: #285491;
  --button-hover-text: #285491;
  --light-primary-gold: #dfa04a;
  --light-primary-gold-hover: #a6865b;
  --input-color: #e9e7e4;
  --transaction-bg: #fff;
  --seperator-bg: #fff;
  --light-primary-light-gray: #f8f7f6;
  --modal-border: #e9e7e4;
  --news-header: linear-gradient(0.46deg, #f8f7f6 -10.7%, rgba(248, 247, 246, 0) 99.72%);
  --bg-pattern: "../../../../assets/images/firstlayer.png";
  --next-bg-pattern: "../../../assets/images/graypattern.png";
  --swiper-button-border: rgba(225, 225, 233, 0.4);
}
[dark-mode="true"] {
  --scrollbar: #353848;
  --main-background-header: #161821;
  --main-background: #07080b;
  --modal-border: #07080b;
  --main-color-header: #ffffff;
  --main-color: #ffffff;
  --main-color-blue: #285491;
  --menu-icon-bg: #242121;
  --shadow: #2854918c;
  --notification-toast-bg: #353848;
  --input-border-color: #161821;
  --input-notification-border-color: #353848;
  --line-color: #353848;
  --notification-border-color: #353848;
  --input-color: #e9e7e4;
  --seperator-border-color: #353848;
  --transaction-bg: #353848;
  --button-text: #000;
  --button-transparent-text: #dfa04a;
  --button-hover-text: #dfa04a;
  --button-border: #dfa04a;
  --news-header: #353848;
  --seperator-bg: #07080b;
  --bg-pattern: "../../../../assets/images/darkmode-pattern.png";
  --next-bg-pattern: "../../../assets/images/darkmode-pattern.png";
  --swiper-button-border: #353848;
}
main {
  background-color: var(--main-background-header);
}
body {
  background-color: var(--main-background-header);
}
.ReactModal__Content {
  background-position: top right !important;
  background-repeat: no-repeat !important;
}
.ReactModal__Content {
  background-image: url("../assets/images/modal-light-bg.png") !important;
}
html[dark-mode="true"] .ReactModal__Content {
  background-size: 490px !important;
  background-image: url("../assets/images/pattern-darkmode-modal.png") !important;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope/Manrope-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope/Manrope-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope/Manrope-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope/Manrope-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope/Manrope-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope/Manrope-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope/Manrope-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Urbanist";
  src: url("../assets/fonts/Urbanist/Urbanist-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Urbanist";
  src: url("../assets/fonts/Urbanist/Urbanist-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Urbanist";
  src: url("../assets/fonts/Urbanist/Urbanist-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Urbanist";
  src: url("../assets/fonts/Urbanist/Urbanist-Regular.ttf") format("truetype");
  font-weight: 400;
}

body {
  font-family: "Manrope", "Verdana", sans-serif;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

button,
input,
select {
  outline: none;
  font-family: "Manrope";
}

select {
  border: none;
}

.container {
  padding: 0 5rem;
}
@media screen and (max-width: 1350px) {
  .container {
    padding: 0 3.5rem;
  }
}

@media screen and (max-width: 1300px) {
  .container {
    padding: 0 2rem;
  }
}
@media screen and (max-width: 1200px) {
  .container {
    padding: 0 1.8rem;
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 0 1.8rem;
  }
}

@media screen and (max-width: 576px) {
  .container {
    padding: 0 1.5625rem;
  }
}

.swiper {
  padding-bottom: 86px !important;
}
.imageSlider.swiper {
  padding-bottom: 0 !important;
  width: 100%;
  height: 100%;
}

.newsSlider .swiper {
  min-height: 486px !important;
}

.swiper-pagination {
  position: absolute;
  left: 0 !important;
  bottom: 56px !important;
  width: auto !important;
  left: auto !important;
  margin: 0;
}

.newsSlider .swiper-pagination {
  bottom: 14px !important;
}

.newsSliderMobile .swiper-pagination {
  bottom: 5% !important;
  right: 0 !important;
}

.newsSliderMobile {
  padding-bottom: 0 !important;
}

.swiper-pagination-bullet {
  width: 13px !important;
  height: 8px !important;
  background: var(--light-primary-gray) !important;
  border-radius: 12px !important;
  transition: all 0.3s;
  opacity: 1 !important;
}
.swiper-pagination-bullet-active {
  background: var(--light-primary-gold) !important;
  width: 41px !important;
  opacity: 1 !important;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  left: 0 !important;
  bottom: 0 !important;
  top: unset !important;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(225, 225, 233, 0.4) 100%);
  opacity: 1 !important;
  border: 1px solid var(--white);
  border-radius: 50%;
  width: 44px !important;
  height: 44px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--main-background-header);
  backdrop-filter: blur(8px);
  border: 6px solid var(--swiper-button-border);
}

.newsSlider .swiper-button-prev,
.swiper-button-next {
  right: 120px !important;
  left: unset !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
  /* background-color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: white;
  background-repeat: no-repeat;
  background-position: center; */
}

.swiper-button-prev {
  background-image: url("../assets/images/leftSlide.png");
}

.swiper-button-next {
  background-image: url("../assets/images/rightSlide.png");
}

.swiper-button-next {
  left: 60px !important;
}

.newsSlider .swiper-button-next {
  right: 60px !important;
  left: unset !important;
}
/* Overriding inline styles by 3rd party component */
.ReactModal__Content {
  background-color: var(--main-background-header) !important;
}
.modal-close-icon path {
  stroke: var(--main-color);
}
.burger-icon rect {
  fill: var(--main-color);
}

.ReactModal__Content {
  flex-direction: column;
}
.seperator {
  width: 100%;
  height: 125px;
  background: var(--seperator-bg);
}

.Toastify {
  font-size: 1.4rem;
}
/* Utils */
.text-center {
  text-align: center !important;
}
.w-100 {
  width: 100% !important;
}
