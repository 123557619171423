@import "../../../assets/styles/helpers.scss";

.element {
  border-bottom: 0.5px solid var(--seperator-border-color);
  padding: 1.3rem 0;
  border-radius: 2px;
  background: var(--main-background-header);
  min-height: 7rem;
}

.elementBottom {
  border-bottom: 1px solid var(--line-color);
  border-radius: 2px;
  background-color: var(--main-background-header);
}

.wrapper {
  margin: 0 17.2rem;
  > div {
    a {
      padding: 0;
      position: relative;
      font-size: 1.4rem;
      line-height: 2.4rem;

      &:before {
        content: "";
        bottom: -0.1rem;
        left: 0;
        right: 0;
        position: absolute;
        width: 100%;
        height: 0.1rem;
      }
    }
  }

  .elem {
    padding: 0.8rem 0;
    margin: 0 2rem;
    text-transform: uppercase;
    color: var(--main-color);
    font-weight: 600;

    &.isDisabled {
      pointer-events: none;
      color: color("light-primary-grey");
    }
  }

  .active {
    color: color("light-primary-gold");
    border-bottom: 0.1rem solid color("light-primary-gold");
    &:before {
      background: color("light-primary-gold");
    }
  }
}

.headerWrapper {
  height: 51px;
  flex: 1;
  align-items: center;
}
.actionsWrapper {
  margin-right: 1rem;
  @media (max-width: 680px) {
    margin-right: 0;
  }
}
.icon {
  margin-right: 1.6rem;

  &.isActive {
    background: color("light-primary-blue");
    span {
      color: color("white") !important;
    }
    span {
      &:first-child {
        svg {
          path {
            stroke: color("white");
          }
        }
      }
    }
  }
  span {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.numberCircle {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.number {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.profileBox {
  .profile {
    margin-right: 1.6rem;
    padding: 0.8rem 1rem !important;
    border: 0.1rem solid var(--line-color);
    position: relative;

    &.active {
      border: 0.1rem solid color("light-primary-gold");
      svg {
        path {
          stroke: color("light-primary-gold");
        }
        rect {
          stroke: color("light-primary-gold");
        }
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.navbarWrapper {
  flex: 1;
}
.websiteNavbar {
  align-items: center;
}

.logo {
  cursor: pointer;
  margin-right: 2.1rem;
  path {
    &:nth-child(9),
    &:nth-child(7),
    &:nth-child(8) {
      fill: color("light-primary-gold");
    }
    fill: var(--main-color);
  }
}

.create {
  font-weight: 600;
  min-height: 4.7rem;
  margin-left: 2rem;

  &.createMobile {
    margin-right: 2rem;
  }
}

.mobileMenu {
  position: absolute;
  left: -100%;
  background-color: var(--main-background-header);
  width: 100%;
  z-index: 999;
  display: block;
  height: 100vh;
  transition: all 0.3s;
  overflow-y: scroll;
  padding: 0 15px;
}

.mobileDeactive {
  left: 0 !important;
}

.icons {
  &.isWalletIcon {
    svg {
      path {
        stroke: var(--main-color);
      }
    }
  }
  &.isAdaBigIcon {
    svg {
      fill: var(--main-color);
    }
  }
}

.mode {
  margin-left: 0 !important;
}
