@mixin center($y : center, $z : center) {
    display: flex;
    justify-content: $y;
    align-items: $z;
}

@mixin standartWhiteText{
    color : var(--white);
    font-weight: 600;
    letter-spacing: 0.02em;
    line-height: 24px;
    font-size: 16px;
}