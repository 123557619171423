$black: #242121;
$white: #fff;
$gold: #dfa04a;
$goldHover: #a6865b;
$borderPrimary: #e9e7e4;
$error: #e13b24;
$text: #8c8987;
$borderSecondary: #f4f2ed;
$blue: #285491;
$bg: #f8f7f6;
$bgSecondary: #f4f2ed;
$lightGray: #F8F7F7;


