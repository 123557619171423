@import "../variables/color";

@mixin _text($fontSize,$fontWeight,$lineHeight,$transform:none,$color:$black){
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: $lineHeight;
  text-transform:$transform;
  color: $color;
}

@mixin text($type, $transform: none, $color: $black) {
  @if $type == "heading-56" {
    font-size: 56px;
    font-weight: 500;
    line-height: 62px;
    color: $color;
    text-transform: $transform;
  } @else if $type == "heading-48" {
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
    color: $color;
    text-transform: $transform;
  } @else if $type == "heading-32" {
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    color: $color;
    text-transform: $transform;
  } @else if $type == "heading-24" {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    color: $color;
    text-transform: $transform;
  } @else if $type == "heading-18" {
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    color: $color;
    text-transform: $transform;
  } @else if $type == "heading-16" {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: $color;
    text-transform: $transform;
  } @else if $type == "heading-12" {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: $color;
    text-transform: $transform;
  } @else if $type == "paragraph-22" {
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    color: $color;
    text-transform: $transform;
  } @else if $type == "paragraph-18" {
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    color: $color;
    text-transform: $transform;
  } @else if $type == "paragraph-16" {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $color;
    text-transform: $transform;
  } @else if $type == "paragraph-14" {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $color;
    text-transform: $transform;
  } @else if $type == "paragraph-12" {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: $color;
    text-transform: $transform;
  }
}
