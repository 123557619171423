@import "../../../assets/styles/helpers.scss";

.element {
  height: 3.6rem;
  width: 4rem;
  padding: 0.9rem 0.6rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: color("light-primary-blue");
  border-radius: 10rem;
  position: relative;
  align-items: center;
  cursor: pointer;

  &.isActive {
    background-color: color("light-primary-black");

    .line {
      display: none;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:before,
    &:after {
      position: absolute;
      left: 1.9rem;
      content: " ";
      height: 2rem;
      width: 0.2rem;
      background: white;
    }
  }
}

.element {
  .line {
    background-color: white;
    border-radius: 0.2em;
    width: 2rem;
    height: 1.2px;
  }

  &:last-child {
    .line {
      height: 1.4px;
    }
  }
}
