@import "../../../assets/styles/helpers.scss";

.element {
  padding-top: 4rem;
  overflow-y: scroll;

  @include media("xl") {
    max-height: 95vh;
  }

  > div {
    height: 100%;
  }
}

.wrapper {
  font-weight: 300;
  color: var(--main-color);
  max-width: 360px;
  word-break: break-all;
  text-align: center;
}

.link {
  font-weight: 600;
  letter-spacing: 0.02em;
  margin-top: 1.6rem;
}

.privacyPolicy {
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: flex;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
  span {
    &:first-child {
      color: color("light-primary-grey");
    }
  }
}

.walletAddressContainer {
  min-height: 70px;
}
