@import "./variables/color.scss";
@import "./mixins/common.scss";
@import "./mixins/media.scss";


$time-transition: 0.2s;
$manrope: 'Manrope', sans-serif;
$nunito-sans: 'Nunito Sans', sans-serif;
$urbanist: 'Urbanist';

@import "./mixins/typography.scss";
@import "./mixins/scrollbar.scss";
@import "./mixins/font.scss";
@import './variables/colors.module.scss';
@import './mixins/color.scss';
@import "./function.scss";
@import "./mixins/customMedia.scss";
@import "./flex.scss";
